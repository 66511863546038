import { ApiError, commonFetch, commonNonAuthFetch, Response } from '../apiHelper';
import {
  AuthenticationUser,
  ChangePasswordDto,
  MeResult,
} from './authenticationTypes';
import { process } from '../../env';

export class AuthenticationApi {
  static signIn = async (email: string, password: string, rememberMe: boolean) => {
    const requestURL = `${process.env.BACKEND_URL}/api/v1/auth/sign-in`;
    const backendResponse = await commonNonAuthFetch(requestURL, {
      method: 'POST',
      body: JSON.stringify({ email, password, rememberMe }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static signOut = async () => {
    const requestURL = `${process.env.BACKEND_URL}/api/v1/auth/sign-out`;
    const backendResponse = await commonNonAuthFetch(requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static me = async (showErrors = true): Promise<MeResult> => {
    const requestURL = `${process.env.BACKEND_URL}/api/v1/auth/me`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
    }, showErrors);
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static changePassword = async (
    { email, password, passwordRepeat, code }: ChangePasswordDto,
  ): Promise<Response<any>> => {
    const requestURL = `${process.env.BACKEND_URL}/api/v1/users/change-password`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
        password,
        passwordRepeat,
        code,
      }),
    });
    if (backendResponse.ok) {
      const r = await backendResponse.json();
      if (r.error) {
        throw r.error;
      }
      return r;
    }
    throw new ApiError(backendResponse);
  };

  static restorePassword = async (email: string): Promise<Response<any>> => {
    const requestURL = `${process.env.BACKEND_URL}/api/v1/users/restore-password`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    });
    if (backendResponse.ok) {
      const r = await backendResponse.json();
      if (r.error) {
        throw r.error;
      }
      return r;
    }
    throw new ApiError(backendResponse);
  };

  static getAllUsers = async (): Promise<{ users: { id: string; email: string; account: { id: string; name: string } | null }[] }> => {
    const requestURL = `${process.env.BACKEND_URL}/api/v1/users/with-accounts`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'GET',
    });
    if (backendResponse.ok) {
      return backendResponse.json();
    }
    throw new ApiError(backendResponse);
  };

  static loginAsUser = async (userId: number | null): Promise<void> => {
    const requestURL = `${process.env.BACKEND_URL}/api/v1/auth/represent-as/${userId}`;
    const backendResponse = await commonFetch(requestURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (backendResponse.ok) {
      return;
    }
    throw new ApiError(backendResponse);
  };
}

export default AuthenticationApi;
