import React, { useState } from 'react';
import * as Yup from 'yup';
import { useMount } from 'react-use';

import { AuthenticationApi } from 'api';

import { LoginAsPage } from './LoginAsPage';
import { submitValidate } from '../../../utils/form-helpers/antd-formik';

const schema = Yup.object({
  userId: Yup.string().required('Выберите пользователя'),
});

export const LoginAsPageContainer = () => {
  const [users, setUsers] = useState<{
    id: string;
    email: string;
    account: {
      id: string;
      name: string;
    } | null;
  }[]>([]);
  useMount(async () => {
    const { users: allUsers } = await AuthenticationApi.getAllUsers();
    setUsers(allUsers);
  });

  return (
    <LoginAsPage
      users={users.map((user) => ({
        value: user.id,
        label: user.account ? `${user.account.name} - ${user.email}` : user.email,
      }))}
      onSubmit={async (values, formikHelpers) => {
        if (!submitValidate(values, schema, formikHelpers)) {
          return;
        }
        await AuthenticationApi.loginAsUser(values.userId);
        window.location.reload();
      }}
    />
  );
};

export default LoginAsPageContainer;
