import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import { ProductSummaryListContextState } from './productSummaryListReducer';
import { getDateTimeString } from '../../../../utils/categoriesHelper';
import { formatDate } from '../../utils/formatDate';
import { ProductItemStatusText } from '../../../../constants/productItem';
import { SchemaType } from '../../../../constants/schema';

export const accountIdSelector = (state: ProductSummaryListContextState) => state.accountId;
export const filterSelector = (state: ProductSummaryListContextState) => state.filter;
export const sortingSelector = (state: ProductSummaryListContextState) => state.sorting;
export const paginationSelector = (state: ProductSummaryListContextState) => state.pagination;
export const listLoadingSelector = (state: ProductSummaryListContextState) => state.loading;
export const listTypeSelector = (state: ProductSummaryListContextState) => state.listType;

export interface ProductListItem {
  id: string;
  created: string;
  eventDate: string;
  status: string;
  hasConsumed: string;
  customerEmail: string;
  customerName: string;
  customerPhone: string;
  title: string;
  categoryName: string;
  price: number;
  revenue: string;
}

export const listItemSelector = createSelector(
  accountIdSelector,
  (state: ProductSummaryListContextState) => state.listItems.productItems,
  (accountId, items) => items.map((item) => {
    let { title } = item.specification;
    const dateString = getDateTimeString(item);
    if (dateString) {
      title += ` ${dateString}`;
    }

    let hasConsumedText = '';
    const hasConsumed = item.consumption.productSpecifications
      ?.filter((productSpecification: any) => productSpecification.consumed === true).length > 0;
    const hasNotConsumed = item.consumption.productSpecifications
      ?.filter((productSpecification: any) => productSpecification.consumed === false).length > 0;
    if (hasConsumed && !hasNotConsumed) {
      hasConsumedText = 'Да';
    } else if (hasConsumed && hasNotConsumed) {
      hasConsumedText = 'Част.';
    } else {
      hasConsumedText = 'Нет';
    }

    let categoryName;
    if (item.specification.type === SchemaType.Base || item.specification.type === SchemaType.BaseCalendar) {
      categoryName = item.specification.categories
        .find((category: any) => category.id === item.item.categoryId)
        .name;
    }
    if (item.specification.type === SchemaType.MultiTicket) {
      categoryName = item.specification.components[0]
        .categories
        .find((category: any) => category.id === item.item.categoryId)
        .name;
    }

    let revenueText = '-';
    if (item.specification.type !== 'multi-ticket') {
      let data = 0;
      item.item.splitPayment.forEach((itSplitPayment: any) => {
        if (itSplitPayment.accountId === accountId && itSplitPayment.productSpecificationId !== null) {
          data += itSplitPayment.amount - itSplitPayment.fee;
        }
      });
      revenueText = (data / 100).toString();
    }

    return {
      id: item.id,
      created: formatDate(item.created),
      eventDate: dayjs(item.eventDate).tz().format('DD.MM.YYYY HH:mm'),
      status: ProductItemStatusText[item.status],
      hasConsumed: hasConsumedText,
      customerEmail: item.customerEmail,
      customerName: item.customerName,
      customerPhone: item.customerPhone,
      title,
      categoryName,
      price: item.item.price,
      revenue: revenueText,
    };
  }),
);
